<template>
  <v-container class="max-container">
    <base-back />
    <v-card
      class="mx-auto card-box"
      tile
      dark
      color="black"
    >
      <v-card-text>
        <div class="layout column align-center">
          <div class="v-card__title justify-center display-1 mb-2">
            <v-avatar size="80">
              <v-img :src="user.photo_url" max-height="80" max-width="80" />
            </v-avatar>
          </div>
          <div class="v-card__subtitle justify-center" style="text-align:center;color:white;margin-top:1em">
            <div class="nickname-title display-1 mb-1">
              {{ user.nickname }}
            </div>
            <div class="email-title">
              Email: {{ user.email }}
            </div>
          </div>
        </div>
      </v-card-text>
      <v-list
        nav
        dense
        color="black"
      >
        <v-subheader class="personal-title">Personal</v-subheader>

        <v-list-item-group v-model="item">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            @click="settingsHandler(item)"
          >
            <v-list-item-avatar v-if="item.icon.includes('https')" size="28">
              <v-img :src="item.icon" />
            </v-list-item-avatar>
            <v-list-item-icon v-else>
              <v-icon v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            v-for="item in localItems"
            :key="item.title"
            @click="settingsHandler(item)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon" />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-footer
        padless
        absolute
        class="font-weight-medium"
      >
        <v-col
          class="align-center justify-center"
        >
          <div class="button-box">
            <v-btn block depressed large class="tile text-capitalize" @click="Signout">Sign out</v-btn>
          </div>
        </v-col>
      </v-footer>
      <v-dialog
        v-model="dialog"
        max-width="290"
        style="background: #1E1E1E!important;"
      >
        <v-card
          dark
          color="black"
          style="background: #1E1E1E!important;"
        >
          <v-card-title class="display-1 mb-2">
            Share with your friends
          </v-card-title>
          <div class="sharing-box">
            <ShareNetwork
              v-for="network in networks"
              :key="network.network"
              :network="network.network"
              :style="{backgroundColor: network.color}"
              :url="sharing.url"
              :title="sharing.title"
              :description="sharing.description"
            >
              <i :class="network.icon" />
              <span>{{ network.name }}</span>
            </ShareNetwork>
          </div>
          <div style="width:10px;height:5px;" />
        </v-card>
      </v-dialog>
    </v-card>

  </v-container>
</template>

<script>
import { getSettingsList } from '@/api/user'
import { getToken } from '@/util/auth'

export default {
  name: 'Settings',
  data: () => ({
    dialog: false,
    item: 0,
    items: [],
    localItems: [
      // { title: 'Add Payment Method', icon: 'mdi-card-bulleted' },
      // { title: 'Payment Methods', icon: 'mdi-card-bulleted' },
      // { text: 'Customer Service', icon: 'mdi-star' },
      // { text: 'Help & Feedback', icon: 'mdi-history' },
      // { text: 'About', icon: 'mdi-check-circle' },
      // { title: 'Share', icon: 'mdi-share' },
    ],
    adminItem: { title: 'Admin Portal', icon: 'mdi-chart-donut-variant' },
    user: {},
    sharing: {
      url: 'https://www.onechannel.one/?utm_source=onechannel&utm_medium=referral',
      title: 'Unlimited Shows in Single Subscription',
      description: 'Subscribe to Netflix, Disney+, HBO Max, Hulu and Prime Video and pay single, discounted subscription rate. Use on your TV, smartphone and tablet. Watch for 7 days for free!'
    },
    networks: [
      // { network: 'email', name: 'Email', icon: 'mdi mdi-email', color: '#333333' },
      { network: 'facebook', name: 'Facebook', icon: 'mdi mdi-facebook', color: '#1877f2' },
      { network: 'twitter', name: 'Twitter', icon: 'mdi mdi-twitter', color: '#1da1f2' }
    ]
  }),
  created() {
    this.user = this.$store.state.user.userInfo.user
    this.sharing.url = `${this.sharing.url}&ref_id=${this.user.referal_code}`
    this.getItems()
  },
  methods: {
    Signout() {
      this.$store.dispatch('user/FedLogOut').then(res => {
        this.$router.push({ 'name': 'login' })
      })
    },
    settingsHandler(item) {
      if (item.title === 'Admin Portal') {
        const currentUrl = window.location.href
        var targetUrl = 'https://admin.test.onechannel.one?auth=1'
        if (!currentUrl.includes('test')) {
          targetUrl = 'https://admin.onechannel.one?auth=1'
        }

        const child_window_handle = window.open(targetUrl, '_blank')
        setTimeout(() => {
          child_window_handle.postMessage(JSON.stringify({ 'jwt': getToken() }), targetUrl)
        }, 1000)
        return
      }

      if (item.title === 'Share') {
        this.dialog = true
        return
      }

      if (item.title === 'Orders') {
        window.location.href = item.url
        return
      }

      if (item.title === 'Add Payment Method') {
        this.$router.push({ 'name': 'AddPayment' })
        return
      }

      if (item.title === 'Payment Methods') {
        this.$router.push({ 'name': 'Cards' })
        return
      }

      if (item.title === 'Account List') {
        this.$router.push({ 'name': 'Accounts' })
        return
      }

      if (item.title === 'Day Pass Wallet') {
        this.$router.push({ 'name': 'MyWallet' })
        return
      }
      window.location.href = item.url
    },
    getItems() {
      getSettingsList().then(res => {
        this.items = res.data
        if (this.$store.state.user.userInfo.is_partner) {
          this.items.unshift(this.adminItem)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.email-title{
    font-size: 12px;
    color: #dddddd;
}

.v-list-item__icon{
    .v-icon{
        font-size: 2em;
    }
}
.v-list-item__content{
    .v-list-item__title{
        line-height: 1.4em;
        font-size: 1em;
        font-weight: bold;
        letter-spacing: 0.02em;
    }
}

.v-application--is-ltr .v-list-item__icon:first-child{
    margin-right: 14px;
}

.personal-title{
    font-size:1em;
    font-weight:bold;
    letter-spacing: 0.05em;
    color:white
}

.divider-line{
    margin-top:6px;
    margin-bottom:6px
}

.button-box{
  margin-top: 10px;
  .v-btn{
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
}

.v-footer{
    border: unset!important;
    background-color: transparent!important;
}

.card-box{
    height: 90vh;
}

.sharing-box{
  text-align: center;
  a{
    padding: 10px;
    margin: 20px;
    text-decoration-line: none;
    border-radius: 5px;
    display: block;
    color: white;
  }
}

.setting-dialog{
  background: #1E1E1E!important;
}

// .v-list-item__avatar{
//       height: 28px!important;
//       width: 28px!important;
// }

</style>
